.react-header-component {
  // margin-bottom: 19px;
  font-family: var(--roboto-regular);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;

  .MuiToolbar-gutters {
    padding-left: 0;
  }
  .header-block-left {
    display: flex;
    align-items: center;
    background-color: var(--primary);
    padding-left: 15px;
    height: 100%;
    min-width: 344px;
    &.blue-icon {
      background-color: var(--white);
      border-bottom: 1px solid var(--ed-mid_white);
    }
  }
  & .project-detail-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    float: left;
    padding-left: 15px;
  }
  & .web-app-version {
    float: left;
    width: auto;
    padding-left: 4px;
  }

  & .read-only {
    width: 20px;
    height: 20px;
    background-image: url('./../../images/Book.svg');
    background-size: contain;
    background-position: center;
    float: left;
    margin-right: 10px;
    background-repeat: no-repeat;
  }
  .read-only-text {
    display: flex;
    align-items: center;
    margin-top: 2px;
    padding: 5px 15px;
    border: 2px solid #f43736;
    border-radius: 50px;
    position: relative;
  }
  .read-only-text p {
    padding-right: 0px;
    color: #f43736;
    float: right;
    font-size: 16px;
    margin-bottom: 0;
  }
  .subHeader {
    height: 100%;
    padding-left: 15px;

    &__container {
      height: 100%;
    }
  }
}

.MuiAppBar-root {
  display: flex;
  justify-content: center;
  padding: 0 25px;
  box-shadow: none !important;

  &.MuiAppBar-colorPrimary {
    background-color: var(--white);
    padding: 0;
  }
  .header-wrapper {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    height: 64px;
  }
  .ed-logo-link {
    cursor: pointer;
  }
  .ed-logo {
    height: 30px;
    float: left;
    cursor: pointer;
  }
  .header-block-right {
    display: flex;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid var(--ed-mid_white);
    // margin-left: 10px;

    & .hamburger-menu {
      display: none;
    }
    .dashboard-icon {
      color: #2c3c50;
      font: var(--size-base) var(--roboto-medium);
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 25px;
      cursor: pointer;
      width: fit-content;
      .MuiSvgIcon-root {
        font-size: 2.5rem;
        margin-left: 5px;
      }
    }

    .header-first {
      flex: 0 1 75%;
      &.switch-user {
        flex: 0 1 65%;
      }
    }
    @media (max-width: 768px) {
      & .hamburger-menu {
        display: inline-block;
      }
    }
  }
  .header-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    flex: 0 1 25%;
    // width: 300px;
    height: 100%;
    cursor: pointer;
    color: rgba(163, 186, 204, 1);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    &.switch-user {
      flex: 0 1 35%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .header-list-item {
    height: 100%;
    line-height: 64px;
    .MuiButton-root:hover {
      background: none;
    }
    .header-list-support {
      height: 100%;
    }
    .header-list-button {
      min-width: 45px;

      &.new {
        padding: 10px;
        margin: 0;
      }
    }
  }
  .header-list-icon-notif {
    height: 24px;
    width: 24px;
    background-image: url(../../images/notification.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  // dropdown css
  .header-dropdown-wrapper {
    background: var(--white);
    height: 100%;
    color: #2c3c50;
    font-size: 14px;
    font-weight: bold;
    float: left;
    margin: 0px;
    letter-spacing: 0.4px;
    cursor: pointer;
  }
  .popper-wrapper {
    left: -15px;
    z-index: 1000000;
    .MuiPaper-root {
      box-shadow: none !important;
    }
    & .notifications.sm {
      display: none;
      @media (max-width: 768px) {
        display: list-item;
        background-color: #182837;
      }

      & .MuiIconButton-label {
        color: #9bb2c4;
      }
    }
  }
  .MuiIconButton-root {
    padding: 10px;
    margin: 3px 2px;
  }
  .MuiButton-root {
    font-size: 16px;
    text-transform: none;
  }
  .MuiMenuItem-root {
    font-size: 16px;
  }
  .header-list-email {
    display: inline-block;
    margin-right: 12px;
    font: var(--size-base) var(--roboto-regular);
    letter-spacing: normal;
    color: #2c3c50;

    &.sm {
      display: none;
      @media (max-width: 768px) {
        display: inline-block;
        vertical-align: middle;
        margin-top: 20px;
        color: #a3bacc;
      }
    }
  }

  .arrow {
    border: solid #9bb2c4;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    margin-top: 3px;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    margin-bottom: 3px;
  }
}
.MuiListItem-root.dp-list {
  min-width: 220px;
  border-bottom: 1px solid var(--ed-mid_white);
  color: #2c3d50;
  font-size: 16px !important;
  height: 52px;
  text-align: left;
  cursor: pointer;
  padding: 16px 0px 16px 15px;
  background-color: var(--white);
  transition: all 0.2s ease-in;
  &:hover {
    background: var(--ed-blue);
    color: var(--white);
    opacity: 1;
  }
  &:last-child {
    cursor: default;
    &:hover {
      pointer-events: none;
      background: none;
    }
  }
  span {
    color: #2c3d50;
    opacity: 0.5;
  }
}

.contract-list {
  z-index: 10;
  max-height: 370px;
  overflow-y: auto;
  overflow-x: hidden;

  & .MuiList-root {
    padding: 0;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0px #ccc;
  }
}
#mh-contract-setting-option {
  position: relative;
}
.MuiListItem-root.contract-item {
  min-width: 235px;
  max-width: 250px;
  border: 1px solid var(--ed-mid_white);
  border-bottom: 0;
  color: #2c3d50;
  font-size: 16px !important;
  height: 52px;
  text-align: left;
  cursor: pointer;
  padding: 16px 10px 16px 25px;
  background-color: var(--white);
  transition: all 0.2s ease-in;
  &:hover {
    background: var(--ed-blue);
    opacity: 1;

    & span {
      color: var(--white);
      opacity: 1;
    }
  }
  &:last-child {
    border-bottom: 1px solid var(--ed-mid_white);
  }
  span {
    color: #2c3d50;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    max-height: 43px;
    word-break: break-all;
    white-space: initial;
    &:hover {
      color: var(--white);
      opacity: 1;
    }
  }
}
#transitions-popper {
  background: #182837;
}
.MuiList-padding.p0 {
  padding: 0 !important;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 0px #ccc;
}

.support-tooltip {
  z-index: 1500 !important;
  .whats-new {
    position: relative;
    cursor: pointer;

    & .notif-badge {
      position: absolute;
    }
  }
}

.header-list-support {
  position: relative;
  .notif-badge {
    position: absolute;
    right: 1px;
    top: 20px;
  }
  .MuiTooltip-popper {
    top: -12px !important;
    left: -60px !important;
    width: 162px;
  }
  .MuiTooltip-tooltip {
    background: var(--white);
    border: 1px solid #0000001f;
    margin: 0;
    padding: 0;
    box-shadow: 0 6px 12px #d1d1d1;
    border-radius: 5px;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -5px;
      right: 14px;
      clear: both;
      width: 10px;
      height: 10px;
      border: 1px solid var(--white);
      border-left: 1px solid #c3c3c3;
      border-top: 1px solid #c6c6c6;
      transform: rotate(45deg);
      z-index: -5;
      background: var(--white);
    }
    ul {
      padding: 0;
      li {
        font-size: 14px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: var(--ed-highlight);
        }
        a {
          font-size: 15px;
          color: #2c3d50;
          width: 100%;
          text-align: center;
          height: 100%;
          padding: 12px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .MuiTooltip-arrow {
      display: none;
    }
  }
}

.menu-wrapper {
  position: absolute;
  /* width: 100%; */
  top: 64px;
  right: -8px;
  z-index: 11;
  .list-wrapper {
    flex-direction: column;
    min-height: 200px;
    background: #224560;
  }
  .header-list-item {
    border-bottom: 1px solid var(--primary);
  }
}

/******* switch user modal styles ****************/
.switch-user-modal .MuiDialog-paper {
  background-color: transparent;
  box-shadow: none;
}

.switch-user-modal-popup {
  .switch-user-pop-up-close {
    margin-top: -8px;
  }
  & .modal-dialog {
    width: 478px;

    & .modal-content {
      border-radius: 2px;
    }

    & .modal-title {
      color: white;
      font-size: 22px;
      text-align: left;
      font-weight: 600;
      margin: 16px 0;
      line-height: 0px;
      padding-left: 9px;
    }

    & .modal-header {
      border-bottom: 0px;
      background-color: #48637a;
    }

    & .modal-body {
      padding: 0;
      & .MuiFormControl-fullWidth {
        padding-left: 11px;
        margin: 40px 26px 18px;
        width: 88%;
      }

      & input {
        color: #334f69;
        font-size: 17px;
        margin-bottom: 8px;
      }

      & .invalid-switch-user {
        background-color: #f97777;
        color: white;
        font-size: 16px;
        padding: 10px;
        padding-left: 27px;
      }

      & .MuiFormHelperText-root.Mui-error {
        font-size: 10px;
      }
    }
  }

  & .modal-footer {
    padding: 20px;
    padding-left: 29px;
    padding-right: 30px;
    border-top: 0px;

    & .switch-user-btn-ok {
      float: right;
      background-color: #48637a;
      border-color: #48637a;
      border-radius: 0px;
      width: 118px;
      height: 44px;
      font-size: 16px;
    }

    & .switch-user-btn-cancel {
      float: left;
      color: #48637a;
      font-size: 19px;
      padding: 0px;
      border: 0px;
      margin-top: 7px;

      &:hover {
        background-color: white;
      }
      &[disabled] {
        color: darkgrey;
      }
    }
  }
}

.year-review {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0;
  img {
    width: 60px;
    padding-bottom: 3px;
  }
}
.viewing-as {
  color: #2c3d50;
  font-size: 14px;
  font-weight: bold;
  padding-right: 0;

  & span {
    display: inline-block;
    margin-top: 5px;
  }
}
/******* switch user modal styles ends ****************/

.read-only-tooltip {
  max-width: 450px !important;
}

.notify-tab-style {
  padding: 15px;
  border-bottom: solid 1px #e0d9d9;
  background-color: var(--white);
  color: #231f20;
}

.notif-dot {
  height: 30px;
  width: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &.success {
    background-image: url(../../images/notification-green.svg);
  }
  &.error {
    background-image: url(../../images/notification-red.svg);
  }
  &.pending {
    background-image: url(../../images/new-notification.svg);
  }
}

.tutorial {
  color: var(--group-header);
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid var(--group-header);
  }
}

.proj-start-guide {
  cursor: pointer;
  color: var(--group-header);
}

.walkthrough_popup {
  width: 50vw;
  min-height: 630px;
  display: block;
  padding-top: 30px;

  .walkthrough_content {
    color: var(--group-header);
    font-size: 14px;
    padding-left: 18px;
  }

  .walkthrough_header {
    & li {
      list-style: none;
      display: inline-block;
      margin: 0 10px;
      cursor: pointer;
      color: var(--secondary);
      &.active {
        color: var(--group-header);
        border-bottom: 1px solid var(--group-header);
      }
      &:hover {
        border-bottom: 1px solid var(--group-header);
      }
    }
    .close_icon {
      float: right;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .walkthrough_footer {
    font-size: 14px;
    position: absolute;
    bottom: 20px;
    right: 0;
    .prev,
    .nxt {
      border: none;
      background: none;
      color: var(--secondary);
      margin-right: 25px;
      &:disabled {
        opacity: 0.2;
        color: #035caf;
      }
    }
  }
}

.dropdown-width {
  width: 300px;
  font: var(--size-base);
  position: absolute;
  left: -150px;
}

.guide-dropdown-width {
  width: 300px;
  font: var(--size-base);
  position: absolute;
  right: -130px;
  top: 66px;
  padding: 10px 25px;
  box-shadow: 1px 1px 5px 0px #ccc;
  border-radius: 10px;
  background-color: white;
  z-index: 1000;

  .guide-hub {
    font: var(--size-base) var(--roboto-medium);
    color: var(--primary);
    cursor: pointer;

    .guide-header {
      display: flex;
      justify-content: space-between;
    }
  }
}
